.button {
  padding: 8px 16px;
  border-radius: 6px;
  font-family: "FunnelSans", sans-serif;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  display: flex;
  max-width: max-content;
  cursor: pointer;
}