@import "../styles/tokens";

.contact .innerWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.contact .innerContent {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1194px;
}

.contactWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 80px;
}

.contactForm {
  background-color: $white;
  border-radius: 20px;
  padding: 24px;
}

.contactFormContainer {
  width: 40%;
}

.contactForm form {
  display: flex;
  flex-direction: column;

  font-family: "Figtree", "sans-serif" !important;
  font-size: 16px;
  font-weight: 500;

  gap: 24px;
}

.contactForm input::placeholder, .contactForm textarea::placeholder {
  font-family: "Figtree", "sans-serif" !important;
  font-size: 16px;
  font-weight: 500;
}

.formField input, .formField textarea, .formField button {
  width: $fillWidth;
  max-width: 100%; // Prevent any unexpected overflow
}

.formField input, .formField textarea {
  padding: 16px;
  border: solid 1px $fieldNormal;
  border-radius: 5px;
}
.contactForm form label {
  color: $gray-color-normal;

  display: flex;
  flex-direction: row;
  gap: 3px;

  width: max-content;
}

.formGroup {
  display: flex;
  min-width: 0;
  gap: 24px;
  justify-content: space-between;
  flex-direction: row;
  //width: $fillWidth;
  width: 100%;

}

.formField {
  width: 100%;
  gap: 16px;
  min-width: 0;

  display: flex;
  flex-direction: column;
  max-width: 100%; // Prevent any unexpected overflow
}


.formField textarea {
  resize: vertical;
  min-height: 100px;
}

.formField .button {
  font-size: 16px;
  //border: 0;
  justify-content: center;
}

.contactInfoContainer h3 {
  font-weight: 600;
  font-size: 16px;
  font-family: "Figtree", sans-serif;
}

.contactIntro {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-self: center;
  align-self: center;
  gap: 16px;
}

.contactInfoContainer {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.contactIntro * {
  margin: 0;
}

.titleField {
  display: block;
  height: 0px;
  padding: 0;
  border: 0;
  margin-bottom: -24px;
}

.checkCircle {
  background: #fff;
  border-radius: 1000px;
  padding: 32px;
  width: max-content;
  height: max-content;
  margin: 0px;
  margin-bottom: -4px;
}

.checkCircle svg {
  vertical-align: middle;
  width: 60px;
  height: 60px;
}

.checkCircle svg path {
  fill: $secondary-color-normal;
}

.feedbackWindow {
  height: 700px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}


.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.9em;
}


/* Responsive design */
@media (max-width: 400px) {

  .contactFormContainer {
    width: 100%;
  }

  .contactForm  {
    padding: 16px;
  }

  .contactForm form {
    gap: 32px;
  }

}
@media (max-width: 800px) {

  .feedbackWindow {
    height: 400px;
  }

  .contactFormContainer {
    width: 100%;
  }

  .contactForm  {
    padding: 24px;
  }
  .contactForm form {
    gap: 32px;
  }

}













