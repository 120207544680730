@import "../styles/tokens";

@import url('https://fonts.googleapis.com/css2?family=Funnel+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.FunnelSans {
  font-family: "FunnelSans", serif;
  font-optical-sizing: auto;
  //font-weight: 500;
  font-style: normal;
  font-variation-settings:
          "wdth" 100;
}

.NavigationDesktop {
  display: flex;
  width: $fillWidth;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;

  width: 100%;
  left: 0;

  padding: 24px 24px;


  gap: 32px;
  width: -webkit-fill-available;
  //bottom: 0;
  z-index: 100;
}

.NavigationDesktop .pageLinksSection {
  width: max-content!important;
  justify-content: center;
  align-items: center;
}

.pageLinksSection a {
  text-decoration: none;
}

.pageLinksSection a:hover {
  text-decoration: underline;
}

.NavigationDesktop .logo {
  height: 40px;
  width: auto;
}

.NavigationDesktop .logo {
  transition: transform 0.3s ease-out; /* Use 'transform' for scaling */
}

.NavigationDesktop .logo:hover {
  transform: scale(1.025); /* Correct property for scaling */
}

.NavigationDesktop .logo a {
  display: flex;
  height: 100%;
}

.pageLinksSection {
  flex-direction: row;
  display: flex;
  gap: 32px;
  margin-left: auto;
}

.NavigationDesktop a svg path {
  fill: $white;
}

.NavigationDesktop a svg {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}

.NavigationDesktop a {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;

  font-family: "Funnel Sans", "sans-serif";
  font-weight: 500;
  font-size: 18px;

  color: $white;

  text-decoration: none;

  width: 15%;
  min-width: max-content;
}


.NavigationDesktop .pageLinksSection a {
  transition: transform 0.3s ease!important;
}
.NavigationDesktop .pageLinksSection .active {
  font-weight: 700!important;
  text-decoration: underline;
  transform: scale(1.075) !important;
}



.NavigationDesktop .logo img {
  width: auto;
  height: 100%;
}

.NavigationDesktop > .languageButton {
  height: 48px;
}

.NavigationDesktop > .languageButton > button {
  height: 48px;
  padding: 0;
  border: 0;
  cursor: pointer;
}

//.NavigationDesktop {
//  height: 48px;
//  width: 48px;
//}

