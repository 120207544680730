@import '../styles/tokens';

.BottomNavigationMobile {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;

}

.BottomNavigationMobile a svg path {
  fill: $white;
}

.BottomNavigationMobile a svg {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}

.BottomNavigationMobile a {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;

  font-family: "FunnelSans", "sans-serif";
  font-weight: 600;
  font-size: 14px;

  color: $white;

  text-decoration: none;

  width: 15%;

  transition: transform 0.3s ease;


  min-width: max-content;
}

.BottomNavigationMobile .active {
  font-weight: 700!important;
  text-decoration: underline;
  //font-size: 16px;
  transform: scale(1.075);
}


@media only screen and (max-width: 1440px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (max-width: 1440px) and (min-resolution: 2dppx) {

  /* Add your styles for high-resolution mobile screens here */
  .BottomNavigationMobile a svg {
    height: 24px;
    width: 24px;
  }

}