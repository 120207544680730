// abstracts
@import 'tokens';

// component styles
@import '../navigation/shared';
@import '../navigation/NavigationBarMobile';
@import '../components/Footer/footer';



.App{
  background-color: $white;
}