@import '../../styles/tokens';
@import "../Button/Button";

.passwordOverlay {
  position: fixed;
  width: 100%;
  background: white;
  height: 100vh;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.6s ease;  // Add a transition for opacity
  opacity: 1;  // Initial state

  &.fade-out {
    opacity: 0;  // Fade out state
  }
}
.passwordOverlay .close-button {
  width: max-content;
  height: max-content;
  padding: 16px;
  border-radius: 1000px;
  border: none;
  background-color: $secondary-color-normal;

  position: absolute;

  right: 16px;
  top: 16px;
}

.passwordOverlay .close-button svg {
  height: 32px;
  width: 32px;
  display: block;
}

.passwordOverlay .close-button svg path {
  fill: $white;
}


.passwordOverlay input::placeholder, .passwordOverlay textarea::placeholder {
  font-family: "Figtree", "sans-serif" !important;
  font-size: 16px;
  font-weight: 500;
}

.passwordOverlay input, .passwordOverlay textarea, .passwordOverlay button {
  width: $fillWidth;
  max-width: 100%; // Prevent any unexpected overflow
}

.passwordOverlay input, .passwordOverlay textarea {
  padding: 16px;
  border: solid 1px $fieldNormal;
  border-radius: 5px;
}

.passwordOverlay button {
  text-align: center!important;
  justify-content: center;
  border: none;
}

.passwordOverlay form{
  width: 50%;
  max-width: 400px;
  min-width: 240px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}


.successfulAccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.successfulAccess .successCircle {
  background-color: $primary-color-normal;
  padding: 24px;
  border-radius: 1000px;
  animation: myAnim 0.5s ease-in-out 0s 1 normal forwards;
}

.successfulAccess .successCircle svg {
  display: block;
}

.successfulAccess .successCircle svg path {
  fill: $primary-color-dark;
}

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: rotate(15deg) scale(0.75);
  }

  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}