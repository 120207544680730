@import './tokens';

.portfolio .center .innerContent {
  text-align: center;
  justify-content: center!important;
  align-items: center;
}

.portfolio .center .ContentBlock {
  text-align: center;
  align-items: center;
}

//.centerContent .innerContent {
//  justify-content: center;
//  align-items: center;
//}



.portfolio .center .headerContent {
  padding-top: 80px;
  padding-bottom: 80px;
}

.portfolio .ContentBlock {
  max-width: 480px!important;
}

.portfolioHeader .innerWrapper {
  margin-top: 80px;
}

.portfolioTileWrapper {
  display: flex;
  flex-direction: row!important;

  width: 100%;
  flex-wrap: wrap;
  gap: 24px!important;
  justify-content: left;
  align-items: center;
}

.projectTileLink {
  border-radius: 3px;
  overflow: hidden;
}

.projectTileLarge, .projectTileLink {
  //width: max-content;
  //height: max-content;
  //display: flex;

  flex-basis: calc(33.33% - 16px); /* Adjusted to account for the gap */
  box-sizing: border-box; /* Ensures padding and borders don't affect width */
}

.projectTileLarge {
  position: relative;
  width: 100%; /* Make the image inside the link fill the flex item */
  height: auto; /* Ensure the image scales properly */
  display: flex;
}

.projectTileLarge img {
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain the aspect ratio of the image */
  object-fit: cover; /* Scale the image properly within its container */
}

.loadingTile {
  position: relative; /* Allows inner elements to be positioned absolutely */
  //width: 100%; /* Adjusts dynamically according to its parent container */
  //height: 240px; /* Ensures a 191:125 aspect ratio */
  aspect-ratio: 191 / 125;
  background-color: #fff; /* Optional: background color for the loading tile */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingTile .projectTileLarge {
  display: block;
}

.loadingTile svg .path {
  stroke: $gray-color-light;
}

//.loadingTile .spinner {
//  position: absolute; /* Positions spinner within the loadingTile */
//  top: 50%;
//  left: 50%;
//  transform: translate(-50%, -50%);
//  width: 30px; /* Adjust spinner size as needed */
//  height: 30px;
//  border: 4px solid rgba(0, 0, 0, 0.1);
//  border-left-color: #000; /* Spinner color */
//  border-radius: 50%;
//  animation: spin 1s linear infinite;
//}

.largeTileOverlay {
  position: absolute; /* Position the overlay on top of the image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */

  color: white; /* Text color inside the overlay */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth fade-in effect */

  backdrop-filter: blur(1.5px);
  padding: 16px;
  gap: 4px;

  cursor: pointer;
}

.largeTileOverlay h4 {
  font-family: FunnelSans, sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.largeTileTitle {
  text-align: left;
}

.largeTileOverlay span {
  font-family: FunnelSans, sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: -4px;
}

.largeTileOverlay p {
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}

.largeTileOverlay p, .largeTileOverlay h4 {
  margin: 0;
}

.projectTileLink:hover  .largeTileOverlay {
  opacity: 1;
}
