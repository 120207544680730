@import '../../styles/tokens';

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: $fillWidth;
}

.footerWrapper .innerContent {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  width: $fillWidth;
  max-width: $maxContainerWidth;
}

.footerInfoSection {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 20%;
  min-width: max-content;
  gap: 16px;
}

.footerInfoSection h5 {
  font-family: "FunnelSans", sans-serif;
  font-weight: 700;
  margin: 0;
  font-size: 18px;
}

.footerInfoSection {
    font-family: "FunnelSans", sans-serif;
    font-weight: 500;
    margin: 0;
    font-size: 16px;
}

.footerInfoSection a {
  text-decoration: none;
}

.footerInfoSection a:hover {
  text-decoration: underline;
}

footer p {
  margin: 0;
}

.footerInfo {
  padding-top: 80px;
  padding-bottom: 80px;
}

.copyright {
  padding-top: 16px;
  padding-bottom: 16px;
}

.sectionInfoPrefix {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.socialsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.socialIcon {
  width: 40px;
  height: 40px;
}
.socialIcon a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.Instagram {
  background-color: #DD2A7B;
}
.Whatsapp {
  background-color: #18B03A;
}
.LinkedIn {
  background-color: #0072B1;
}
.socialIcon img {
  display: flex;
  width: 32px;
  height: 32px;
}
.footerInfoSection a {
  color: $white;
}

.footer {
  width: 100%;
  left: 0;
}

.footer, .contentFooter {
  display: flex;
  flex-direction: column;
}

.footer .innerWrapper, .contentFooter .innerWrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  gap: 24px;
}

.footer .ContentBlock, .contentFooter .ContentBlock {
  max-width: 600px;
}

.DecorativeAsset {
  display: flex;
}

.sectionInfoPrefixLabel {
  font-family: "FunnelSans", sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}

.trueInnerFooter {
  padding: 0!important;
}

.trueInnerFooter .footerInfo {
  padding-left: 32px;
  padding-right: 32px;
}

.contentFooter .innerWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contentFooter .innerWrapper .ContentBlock {
  text-align: center;
}