@import '../styles/tokens';

.NavigationMobile {
  display: flex;
  width: $fillWidth;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  padding: 16px 24px;
}

.logo {
  width: 64px;
  height: 35.25px;
}

.logo img {
  width: 100%;
  height: auto;
}

.NavigationMobile > .languageButton {
  height: 48px;
}

.NavigationMobile > .languageButton > button {
  height: 48px;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.NavBarFiller {
  height: 48px;
  width: 48px;
}

@media only screen and (max-width: 1440px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (max-width: 1440px) and (min-resolution: 2dppx) {

  /* Add your styles for high-resolution mobile screens here */
  .logo {
    width: 48px;
    height: 26.45px;
  }

  .NavBarFiller {
    height: 32px;
    width: 32px;
  }

  .NavigationMobile > .languageButton {
    height: 32px;
  }

  .NavigationMobile > .languageButton > button {
    height: 32px;
    padding: 0;
    border: 0;
    cursor: pointer;
  }

  .mobileLogo {
    height: 100%;
  }
}