@import url('https://fonts.googleapis.com/css2?family=Funnel+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.FunnelSans {
  font-family: "FunnelSans", serif;
  font-optical-sizing: auto;
  //font-weight: 500;
  font-style: normal;
  font-variation-settings:
          "wdth" 100;
}


@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
.Figtree {
  font-family: "Figtree", serif;
  font-optical-sizing: auto;
  //font-weight: 500;
  font-style: normal;
  font-variation-settings:
          "wdth" 100;
}

.DecorativeAsset {
  transform:translateZ(0)
}

h1, h2, h3, h4, h5, h6 {
  font-family: "FunnelSans", sans-serif;
  font-weight: bold;
  margin: 0;
}

h3 {
  font-size: 24px;
}
h4 {
  font-size: 16px;
}

p {
  font-family: "Figtree", sans-serif;
  font-weight: normal;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  //align-items: center;
}

.centerContent .innerContent {
  align-items: center;
  justify-content: center!important;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  font-size: 24px;
  color: #333;
}






.spinner {
  animation: rotate 2s linear infinite;
}

.path {
  stroke: #1D1D1B; /* Set the color of the spinner */
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

/* Rotate the spinner */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* Create the dash effect */
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/* Initial load fade-in */
.fade-in-init {
  opacity: 0;
  animation: fadeInInitAnimation 0.5s ease forwards;
}

@keyframes fadeInInitAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Route change fade-in */
.fade-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Add this class to immediately set opacity to 0 on route change */
.fade-wrapper.hide {
  opacity: 0; /* Instantly hide the content */
  transition: none; /* Disable transition when hiding */
}

/* This is used to transition opacity back to 1 */
.fade-wrapper.active {
  opacity: 1; /* Fade in */
  transition: opacity 0.5s ease-in-out; /* Smooth fade-in transition */
}


$maxContainerWidth: 1194px;
$fillWidth: -webkit-fill-available;

//$primary-color-light: ;
$primary-color-normal: #00E987;
$primary-color-dark: #085A38;

//$secondary-color-light: ;
$secondary-color-normal: #0075D3;
$secondary-color-dark: #00192E;

$gray-color-light: #DADADA;
$gray-color-normal: #1D1D1B;
$gray-color-dark: #151515;

$white: #ffffff;
$black: #000000;

$fieldNormal: #DADADA;
$fieldFocus: #4B4B4B;

// RESPONSIVE TOKENS
$DesktopNavigationCutoff: 1000px;

.footer {
  margin-bottom: 89px;
}

.content {
  margin-top: 80px;
}



.elevation100 {
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.1);
}
.elevation010 {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.elevation001 {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.25);
}

.ContentBlock {
  text-align: left;
}

.ContentBlock p {
  font-size: 16px;
  margin: 0;
  color: #333333;
  margin-top: 16px;

  font-weight: 400;

}

.green .ContentBlock p {
  color: #085A38;
}
.blue .ContentBlock p {
  color: #FFFFFF;
}
.reviewSection .blueInverted p {
  color: #333333!important;
}

.ContentBlock h1 {
  font-size: 24px;
  margin: 0;
  margin-bottom: 16px;
}

.BottomNavigationMobile, .NavigationMobile {
  z-index: 100;
}

.innerWrapper {
  padding: 0 32px;
  width: -webkit-fill-available;
  //max-width: 1194px;
}

.outerWrapper {
  width: -webkit-fill-available;
}

.headerContent {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contentWrapper, .protectedContentContent {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.unlockedCircle {
  display: flex;
  position: absolute;
  top: -12px;
  right: -12px;
  background: $secondary-color-normal;
  border-radius: 100px;
  padding: 14px;
  z-index: 90;
  //border: 1px solid $gray-color-light;
  width: 10%;
  max-width: 48px!important;
  //height: auto;
}


.unlockedCircle svg path {
  fill: $white;
}

.unlockedCircle svg  {
  width: 48px;
  height: 48px;
  max-height: fit-content;
}

.projectTileLink {
  overflow: visible;
}

.top {
  margin-bottom: -1px;
}
.bottom {
  margin-top: -1px;
}

.button {
  box-sizing: border-box;
  transition: all 0.3s ease; /* Optional smooth transition */
  border: 2px solid transparent;
}

.button.blueInverted:hover {
  background-color: $secondary-color-dark;
  color: $white;
  border-color: $white;
}
.button.blue:hover {
  background-color: $white;
  color: $secondary-color-normal;
  border-color: $secondary-color-normal;
}
.button.black:hover {
  background-color: $white;
  color: $gray-color-normal;
  border-color: $gray-color-normal;
}
.button.greenInverted:hover {
  background-color: $primary-color-dark;
  color: $white;
  border-color: $white;
}

.green {
  background-color: $primary-color-normal;
  color: $primary-color-dark;
}
.blue {
  background-color: $secondary-color-normal;
  color: $white;
}
.blueInverted {
  background-color: $white;
  color: $secondary-color-dark;
}
.greenInverted {
  background-color: $white;
  color: $primary-color-dark;
}

.black {
  background-color: $gray-color-dark;
  color: $white;
}
.grayNeutral {
  background-color: $gray-color-normal;
  color: $white;
}

.grayDark {
  background-color: $gray-color-dark;
  color: $white;
}

.homeHeader .aboutHeader {
  justify-content: flex-end;
}

//.overWritePadding {
//  padding-top: 80px;
//  padding-bottom: 80px;
//}


.homeHeader .aboutHeader {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

/* Responsive design */
@media (max-width: 800px) {

  .homeHeader .aboutHeader {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
    justify-content: center;
  }

  .homeHeader .heroSection {
    width: 100%;
  }

  .homeHeader .ContentFillerImage img {
    height: auto !important;
    width: auto !important;
  }
}



@media (min-width: 750px) {
  .homeHeader .ContentFillerImage img {
    height: auto !important;
    width: 100% !important;
  }
}

@media (min-width: 750px) {
  .homeHeader .ContentFillerImage img {
    height: 100% !important;
    width: auto !important;
  }
}

@media (min-width:925px) and (max-width: 950px) {
  .homeHeader .ContentFillerImage img {
    height: auto !important;
    width: 100% !important;
  }
}

@media (min-width:750px) and (max-width: 800px) {
  .homeHeader .ContentFillerImage img {
    height: auto !important;
    width: 100% !important;
  }
}

.uxdCustomizer img {
  width: auto!important;
  height: 100%;
  left: -40px;
}

.uidCustomizer img {
  width: auto!important;
  height: 100%;
  left: -80px;
}

.brandingCustomizer img {
  width: auto!important;
  height: 100%;
  left: -70px;
}

.iconCustomizer img {
  width: auto!important;
  height: 100%;
  left: -200px;
}

.photographyCustomizer img {
  width: auto!important;
  height: 100%;
  left: -150px;
}

.videoCustomizer img {
  width: auto!important;
  height: 100%;
  left: -150px;
}

.userresearchCustomizer img {
  width: auto!important;
  height: 100%;
  left: -60px;
}

.informationarchitectureCustomizer img {
  width: auto!important;
  height: 100%;
  left: -188px;
}
.programmingCustomizer img {
  width: auto!important;
  height: 100%;
  left: -50px;
}
.artificialintelligenceCustomizer img {
  width: auto!important;
  height: 100%;
  left: -239px;
}
.accessibilityCustomizer img {
  width: 100% !important;
  height: auto !important;
  left: 0;
  top: -120px;
}


/* Responsive design */
@media (min-width: 950px) {
  .homeHeader .ContentFillerImage img {
    width: 100% !important;
    height: auto !important;
  }
}


/* Responsive design */
@media (max-width: 800px) {

  .homeHeader .ContentFillerImage {
    justify-content: center!important;
  }



  * {
    word-wrap: break-word;
  }

  .contentWrapper .innerContent {
    display: flex;
    flex-direction: column;
  }

  .withImage .ContentBlock {
    flex: 1 1;
    max-width: 100%;
  }

  .ContentImage.tall {
    padding-top: 40%;
    max-height: 200px;
    max-width: none;
    width: 30%;
  }
  .ContentImage.square {
    padding-top: 60%;
    max-height: 200px;
    max-width: none;
    width: 60%;

  }
  .ContentImage.wide, .thomasThinkImageCustomizer {
    padding-top: 40%;
    max-height: 200px;
    max-width: none;
    width: 60%;
  }



  .ProjectHighlightWrapper {
    gap: 16px;
    justify-content: space-between;
  }

  .goodHandsSection {
    text-align: left;
  }

  .numberCounterSection {
    flex-direction: row;
    text-align: center!important;

    flex-wrap: wrap;
    justify-content: center;
    max-width: 360px;
    width: -webkit-fill-available;
  }

  .numberCounterItem {
    align-items: center;
    width: 100%;
  }


  .contentWrapper, .protectedContentContent {
    gap: 40px;
  }

  .footer .innerWrapper, .contentFooter .innerWrapper, .headerContent, .footerInfo, .aboutHeader, .contactWrapper, .portfolioItemHeader .headerContent {
    padding-top: 40px!important;
    padding-bottom: 40px!important;
  }

  .portfolioItemHeader .container {
    width: 100%;
  }
  .portfolioItemHeader .container .image-container {
    min-width: 0;
  }

  .portfolioItemHeader .item-intro {
    flex-direction: column;
    gap: 16px;
    display: flex;
    min-width: 0;
  }

  .portfolioItemHeader .info-section {
    flex-direction: column;
    gap: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .portfolioItemHeader .clientContainer,
  .portfolioItemHeader .roleContainer,
  .portfolioItemHeader .yearContainer,
  .portfolioItemHeader .skillsContainer
  {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }

  .portfolioItemHeader .skillsContainer .info-section {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .portfolioItemHeader .skillsContainer .skills-badge {

    width: max-content;
  }

















  .footerInfo .innerContent {
    flex-direction: column!important;
    gap: 48px;
  }

  .footerInfo .infoSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .contactIntro {
    gap: 8px;
    align-self: flex-start;
  }

  .contactWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }




  .formGroup {
    gap: 32px;
    flex-direction: column;
  }

  .formField {
    gap: 8px;
  }

  .footerContent {
    flex-direction: column!important;
    gap: 48px;
  }

  .thomasThinkImageCustomizer {
    padding-top: 40% !important;
  }

  .projectTileLink {
    border-radius: 15px;
  }

  .creativePastCustomizer {
    padding-top: 55.5% !important;
  }

  .portfolioTileWrapper {
    flex-direction: column!important;
    gap: 24px!important;
  }
  .projectTileLarge, .projectTileLink {
    width: 100%;
  }

  .imageLeft .ContentImage {
    order: 1!important;
  }
  .imageRight .ContentImage {
    order: 1!important;
  }

  .ContentBlock {
    order: 2!important;
  }

  .portfolioHeader .innerWrapper {
    margin-top: 0!important;
  }


}

.home .portfolioHeader .innerWrapper {
  margin-top: 0!important;
}

/* Responsive design */
@media (max-width: 400px) {

  .projectTileLink {
    border-radius: 10px;
  }


  .homeHeader .ContentFillerImage {
    justify-content: center!important;
  }

  .homeHeader .ContentFillerImage img {
    height: 100% !important;
    width: auto !important;
  }

  * {
    word-wrap: break-word;
  }

  .contentWrapper .innerContent {
    display: flex;
    flex-direction: column;
    padding-top: 32px!important;
    padding-bottom: 32px!important;
  }

  .withImage .ContentBlock {
    flex: 1 1;
    max-width: 100%;
  }

  .ContentImage.tall {
    padding-top: 140%;
    max-height: 200px;
    max-width: none;
    width: 100%;

  }
  .ContentImage.square {
    padding-top: 100%;
    max-height: 200px;
    max-width: none;
    width: 100%;

  }
  .ContentImage.wide, .thomasThinkImageCustomizer {
    padding-top: 80%;
    max-height: 200px;
    max-width: none;
    width: 100%;
  }

  .ProjectHighlightWrapper {
    gap: 16px;
    justify-content: space-between;
  }

  .goodHandsSection {
    text-align: left;
  }

  .numberCounterSection {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .numberCounterItem {
    width: 100%;
  }
  .numberCounterItem * {
    width: 100%;
  }

  .contentWrapper, .protectedContentContent {
    gap: 40px;
  }

  .footer .innerWrapper, .contentFooter .innerWrapper, .headerContent, .footerInfo, .aboutHeader, .contactWrapper, .portfolioItemHeader .headerContent {
    padding-top: 40px!important;
    padding-bottom: 40px!important;
  }

  .portfolioItemHeader .container {
    width: 100%;
  }
  .portfolioItemHeader .container .image-container {
     min-width: 0;
  }

  .portfolioItemHeader .item-intro {
    flex-direction: column;
    gap: 16px;
    display: flex;
    min-width: 0;
  }

  .portfolioItemHeader .info-section {
    flex-direction: column;
    gap: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .portfolioItemHeader .clientContainer,
  .portfolioItemHeader .roleContainer,
  .portfolioItemHeader .yearContainer,
  .portfolioItemHeader .skillsContainer
  {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }

  .portfolioItemHeader .skillsContainer .info-section {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .portfolioItemHeader .skillsContainer .skills-badge {

    width: max-content;
  }

















  .footerInfo .innerContent {
    flex-direction: column!important;
    gap: 48px;
  }

  .footerInfo .infoSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .contactIntro {
    gap: 8px;
  }

  .contactWrapper {
    flex-direction: column;
    gap: 24px;
  }

  .formGroup {
    gap: 32px;
    flex-direction: column;
  }

  .formField {
    gap: 8px;
  }

  .footerContent {
    flex-direction: column!important;
    gap: 48px;
  }

  .thomasThinkImageCustomizer {
    padding-top: 70% !important;
  }

  .creativePastCustomizer {
    padding-top: 87.5% !important;
  }

  .portfolioTileWrapper {
    flex-direction: column!important;
    gap: 8px!important;
  }
  .projectTileLarge, .projectTileLink {
    width: 100%;
  }

  .imageLeft .ContentImage {
    order: 1!important;
  }
  .imageRight .ContentImage {
    order: 1!important;
  }

  .ContentBlock {
    order: 2!important;
  }


}



/* Responsive design */
@media (min-width: 400px) {
  .trueFooter {
    margin-bottom: 0!important;
  }

  .homeHeader .aboutHeader {
    padding-top: 15% !important;
    padding-bottom: 15% !important;
  }

  .homeHeader .ContentFillerImage {
    justify-content: flex-start;
  }

  .App > .content {
    margin-top: 64px;
  }

}

/* Responsive design */
@media (min-width: 1000px) {
  .trueFooter {
    margin-bottom: 0!important;
  }

  .homeHeader .aboutHeader {
    padding-top: 10%;
    padding-bottom: 10%;
  }

}


/* Responsive design */
@media (min-width: 1450px) {
  .trueFooter {
    margin-bottom: 0!important;
  }

  .homeHeader .aboutHeader {
    padding-top: 15% !important;
    padding-bottom: 15% !important;
  }

  .App > .content {
    margin-top: 96px;
  }

}

.footerWrapper .ContentBlock {
  order: initial!important;
}


/* Responsive design */
@media (min-width: 1920px) {

  //.homeHeader .aboutHeader {
  //  padding-top: 320px !important;
  //  padding-bottom: 320px !important;
  //}

}

.footer .trueInnerFooter {
  padding-bottom: 0px !important;
  margin-bottom: -1px!important;
}

@media only screen and (max-width: 1440px) and (-webkit-min-device-pixel-ratio: 2),
only screen and (max-width: 1440px) and (min-resolution: 2dppx) {
  .content {
    margin-top: 64px;
  }

  .footer {
    margin-bottom: 80px;
  }
}



