@import '../styles/tokens';

.NavigationMobile, .BottomNavigationMobile, .NavigationDesktop {
  background-color: $gray-color-normal;
}

.NavigationMobile > .languageButton > button,
.NavigationDesktop > .languageButton > button {
  background-color: transparent;
}

.NavigationMobile, .BottomNavigationMobile {
  width: 100%;
  left: 0;
}

.trueInnerFooter {
  display: flex;
  flex-direction: column;
}

.footerInfo {
  align-self: center;
}

.returnPortfolioButton {
  font-family: "FunnelSans", sans-serif;
  font-weight: 500;
  margin: 0;
  font-size: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  gap: 8px;
  text-decoration: none;

  color: $gray-color-normal;
}

.returnPortfolioButton:hover {
  text-decoration: underline;
}
.returnPortfolioButton svg {
  width: 16px;
  height: 16px;
}
.returnPortfolioButton svg path {
  fill: $gray-color-normal;
}

.scroll-to-top {
  position: fixed;
  bottom: -56px; /* Initially hidden below the screen */
  right: 24px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 40px;
  //width: 40px;
  //height: 40px;
  padding: 16px 40px;
  font-size: 20px;
  cursor: pointer;
  transition: bottom 0.3s ease-in-out; /* Smooth slide-in effect */
  z-index: 1000;
}

.scroll-to-top.show {
  bottom: 24px; /* Visible position */
}

.scroll-to-top:hover {
  background-color: #555;
}

@media (max-width: 1450px) {
    .scroll-to-top {
      bottom: 4px; /* Initially hidden below the screen */
      right: 24px;
      //left: 24px;
      padding: 24px 32px;
      //height: 8px;
      //width: 8px;
      font-size: 20px;
      z-index: 99;
    }

    .scroll-to-top.show {
      bottom: 104px; /* Visible position */
    }
}
